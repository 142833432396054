import React, { useState } from "react";
import classnames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { Dialog } from "primereact/dialog";

import styles from "./new-time-item.module.css";

interface AddTimeItemProps {
  enabled: boolean;
  onAddTimeItem: (itemName: string, itemDuration: number) => void;
}

const TIME_OPTIONS = [
  { label: "(s)", value: "1" },
  { label: "(m)", value: "60" },
  { label: "(h)", value: "3600" }
];

export default ({ enabled, onAddTimeItem }: AddTimeItemProps) => {
  const [timeItemName, setTimeItemName] = useState("");
  const [timeItemDuration, setTimeItemDuration] = useState("");
  const [durationType, setDurationType] = useState("1");
  const [showAlert, setShowAlert] = useState(false);

  const onClickAddItem = () => {
    if (timeItemName !== "" && timeItemDuration !== "") {
      onAddTimeItem(
        timeItemName,
        parseInt(timeItemDuration, 10) * parseInt(durationType, 10)
      );
      setTimeItemDuration("");
      setTimeItemName("");
    } else {
      setShowAlert(true);
    }
  };
  return (
    <div
      className={classnames(styles.container, enabled ? "" : styles.disabled)}
    >
      <Dialog
        header="Hey hey hey!"
        visible={showAlert}
        style={{ width: "70vw" }}
        onHide={() => setShowAlert(false)}
        closable={true}
        position="top"
        blockScroll
      >
        <p>Duration &amp; Name are mandatory!</p>
      </Dialog>
      <h2>Add TimeItem</h2>
      <span className={classnames("p-float-label", styles.formElement)}>
        <InputText
          className={styles.formElement}
          id="time-item-duration"
          value={timeItemDuration}
          type="number"
          onChange={(e: any) => setTimeItemDuration(e.target.value)}
        />
        <label htmlFor="time-item-duration">Duration</label>
      </span>
      <SelectButton
        className={styles.formButton}
        value={durationType}
        options={TIME_OPTIONS}
        onChange={e => setDurationType(e.value)}
      ></SelectButton>
      <span className={classnames("p-float-label", styles.formElement)}>
        <InputText
          className={styles.formElementInput}
          id="time-item-name"
          value={timeItemName}
          onChange={(e: any) => setTimeItemName(e.target.value)}
        />
        <label htmlFor="time-item-name">Name</label>
      </span>
      <span className={styles.formElement}>
        <Button
          className={styles.formElementInput}
          icon="pi pi-check"
          iconPos="right"
          label="Add"
          onClick={onClickAddItem}
          disabled={!timeItemDuration && !timeItemName}
        />
      </span>
    </div>
  );
};
