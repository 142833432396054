export const sendBrowserNotification = (message: string) => {
  if ("Notification" in window && Notification.permission === "granted") {
    new Notification(message);
  }
};

export const sendNotification = (
  localNotification: any,
  title: string,
  message: string,
  type?: string
) => {
  sendBrowserNotification(message);

  localNotification.show({
    severity: type || "success",
    summary: title,
    detail: message
  });
};

const isNewNotificationSupported: () => number = () => {
  if (!window.Notification || !Notification.requestPermission) {
    return 0;
  }
  if (Notification.permission === "granted") {
    return 2;
  }
  try {
    new Notification("");
  } catch (e) {
    if (e.name === "TypeError") {
      return 0;
    }
  }

  return 1;
};

export const requestPermission = () => {
  if (isNewNotificationSupported() > 0) {
    Notification.requestPermission();
  }
};
