import { ILists } from "../interfaces/list";

const STORAGE_NAME = "stm-lists";

export const save = (list: ILists) => {
  localStorage.setItem(STORAGE_NAME, JSON.stringify(list));
};

export const read: () => ILists = () => {
  return JSON.parse(
    localStorage.getItem(STORAGE_NAME) || '{ "order": [] }'
  ) as ILists;
};
