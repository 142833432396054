import React, { useState, useEffect } from "react";

import { Growl } from "primereact/growl";

import ListControl from "../../components/list-control";
import List from "../../components/list";
import NewList from "../../components/new-list";
import NewTimeItem from "../../components/new-time-item";
import { ILists, IList } from "../../interfaces/list";
import { ITimer } from "../../interfaces/timer";
import { ITimeItem } from "../../interfaces/time-item";
import { uuidv4 } from "../../utils/uuid";
import { save, read } from "../../utils/localStorage";
import { sendNotification } from "../../utils/notifications";

import styles from "./home.module.css";
import { playBeep } from "../../utils/sound";

interface HomeProps {
  mute: boolean;
}

const HomePage = ({ mute }: HomeProps) => {
  const [loaded, setLoaded] = useState(false);
  const [lists, setLists] = useState<ILists>({ order: [] });
  const [selectedList, setSelectedList] = useState("");
  const [localNotification, setLocalNotification] = useState();
  const [player, setPlayer] = useState<ITimer>({
    active: false,
    currentDuration: 0,
    currentStep: 0,
    initialTime: 0,
    totalSteps: 0,
    totalTime: 0,
    steps: []
  });

  useEffect(() => {
    if (!loaded) {
      const loadedList = read();
      setLists(loadedList);
      if (loadedList.order.length > 0) {
        setSelectedList(loadedList.order[0]);
      }
      setLoaded(true);
    }

    save(lists);

    if (player.active) {
      const playerInterval = setInterval(() => {
        const delta = Date.now() - player.initialTime;
        const totalTime = Math.ceil(delta / 1000);

        if (totalTime === player.currentDuration) {
          const nextStep = player.currentStep + 1;

          if (nextStep < player.totalSteps) {
            playBeep(mute, nextStep + 1);
            sendNotification(
              localNotification,
              "Next step!",
              player.steps[nextStep].title
            );

            setPlayer({
              ...player,
              currentStep: nextStep,
              currentDuration:
                player.currentDuration + player.steps[nextStep].duration
            });
          } else {
            playBeep(mute, 4, "SLOW");
            sendNotification(
              localNotification,
              "Finish!",
              "The routine has been completed",
              "error"
            );
            setPlayer({
              ...player,
              active: false
            });
          }
        }
      }, 1000);

      return () => clearInterval(playerInterval);
    }
  }, [loaded, player, lists, localNotification, mute]);

  const onChangeOrder = (e: any) => {
    setLists({
      ...lists,
      [selectedList]: {
        ...lists[selectedList],
        items: e.value
      }
    });
  };

  const onAddList = (name: string) => {
    const newList: IList = {
      id: uuidv4(),
      label: name,
      items: []
    };

    setLists({
      ...lists,
      [newList.id]: newList,
      order: [...lists.order, newList.id]
    });

    setSelectedList(newList.id);
  };
  const onSelectList = (e: any) => {
    setSelectedList(e.value);
  };

  const onRemoveList = (e: any) => {
    const removedId = e.value;
    const newList = JSON.parse(JSON.stringify(lists));

    delete newList[removedId];
    const indexOf = newList.order.indexOf(removedId);
    newList.order.splice(indexOf, 1);

    setLists(newList);
  };

  const onRemoveTimeItem = (id: string) => {
    const newSelectedList = JSON.parse(
      JSON.stringify(lists[selectedList])
    ) as IList;

    const index = newSelectedList.items.findIndex(
      (item: ITimeItem) => item.id === id
    );

    if (index !== -1) {
      newSelectedList.items.splice(index, 1);
    }

    setLists({
      ...lists,
      [newSelectedList.id]: newSelectedList
    });
  };

  const onAddTimeItem = (title: string, duration: number) => {
    const newSelectedList = JSON.parse(
      JSON.stringify(lists[selectedList])
    ) as IList;

    newSelectedList.items = [
      ...(newSelectedList.items || []),
      { id: uuidv4(), title, duration }
    ];

    setLists({
      ...lists,
      [newSelectedList.id]: newSelectedList
    });
  };

  const toggleListStatus = () => {
    if (player.active === false) {
      const items = (lists[selectedList] as IList).items;
      sendNotification(localNotification, "Let's start!", items[0].title);
      playBeep(mute, 1);
      setPlayer({
        active: true,
        initialTime: Date.now(),
        totalTime: 0,
        currentStep: 0,
        currentDuration: items[0].duration,
        steps: items,
        totalSteps: items.length
      });
    } else {
      setPlayer({
        ...player,
        active: false
      });
    }
  };

  return (
    <>
      <Growl ref={el => setLocalNotification(el)} />
      <div className={styles.homeContainer}>
        <NewList onAddList={onAddList} />
        <ListControl
          lists={lists}
          selectedList={selectedList}
          onRemoveList={onRemoveList}
          onSelectList={onSelectList}
          status={player.active}
          onToggleList={toggleListStatus}
        />
        <List
          items={selectedList ? (lists[selectedList] as IList).items : []}
          onChange={onChangeOrder}
          onRemoveTimeItem={onRemoveTimeItem}
        />

        <NewTimeItem onAddTimeItem={onAddTimeItem} enabled={!!selectedList} />
      </div>
    </>
  );
};

export default HomePage;
