import React, { memo } from "react";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import { Button } from "primereact/button";

import { ILists, IList } from "../../interfaces/list";

import styles from "./list-control.module.css";

interface SwitchButtonProps {
  lists: ILists;
  status: boolean;
  selectedList: string;
  onToggleList: () => void;
  onRemoveList: (e: any) => void;
  onSelectList: (e: any) => void;
}

export default memo(
  ({
    lists,
    status,
    onToggleList,
    onRemoveList,
    selectedList,
    onSelectList
  }: SwitchButtonProps) => {
    const flatList = lists.order.map((id: string) => lists[id] as IList);

    return (
      <div className={styles.container}>
        <Button
          icon="pi pi-times-circle"
          onClick={onRemoveList}
          className="p-button-danger"
          disabled={!selectedList}
        />
        <div className={styles.dropdown}>
          <Dropdown
            disabled={!flatList.length}
            value={selectedList}
            optionValue="id"
            options={flatList}
            onChange={onSelectList}
            placeholder="Select a List"
            style={{ width: "100%", borderRadius: 0 }}
          />
        </div>
        <ToggleButton
          className={!!selectedList ? "" : styles.disabledPlay}
          checked={!status}
          onChange={onToggleList}
          onLabel="Play"
          offLabel="Stop"
          onIcon="pi pi-play"
          offIcon="pi pi-power-off"
        />
      </div>
    );
  }
);
