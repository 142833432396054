export const getDuration = (duration: number) => {
  let restTime = duration;
  const time = [];
  if (restTime > 3600) {
    time.push(Math.ceil(restTime / 3600));
    if (time[0] < 10) {
      time[0] = "0" + time[0];
    }
    restTime %= 3600;
  } else {
    time.push("00");
  }

  if (restTime > 60) {
    time.push(Math.ceil(restTime / 60));
    if (time[1] < 10) {
      time[1] = "0" + time[1];
    }
    restTime %= 60;
  } else {
    time.push("00");
  }

  if (restTime < 10) {
    time.push("0" + restTime);
  } else {
    time.push(restTime);
  }

  return time.join(":");
};
