import React, { useState } from "react";
import classnames from "classnames";
import { InputSwitch } from "primereact/inputswitch";

import HomePage from "./pages/home";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import { requestPermission } from "./utils/notifications";

import "./App.css";

const App = () => {
  const [mute, setMute] = useState(true);
  requestPermission();
  return (
    <div className="App">
      <HomePage mute={mute} />
      <h1>
        <img src="/logo.png" alt="Routiny" width="50px" />
        <div className="volume-container">
          <i
            className={classnames("pi", {
              "pi-volume-up": !mute,
              "pi-volume-off": mute
            })}
          ></i>
          <InputSwitch checked={!mute} onChange={() => setMute(!mute)} />
        </div>
      </h1>
    </div>
  );
};

export default App;
