import React, { useState } from "react";
import classnames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import styles from "./new-list.module.css";

interface AddListProps {
  onAddList: (name: string) => void;
}

export default ({ onAddList }: AddListProps) => {
  const [listName, setListName] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const onClick = () => {
    if (listName !== "") {
      setListName("");
      onAddList(listName);
    } else {
      setShowAlert(true);
    }
  };
  return (
    <div className={styles.container}>
      <Dialog
        header="Hey hey hey!"
        visible={showAlert}
        style={{ width: "70vw" }}
        onHide={() => setShowAlert(false)}
        closable={true}
        position="top"
        blockScroll
      >
        <p>The "name" can not be empty</p>
      </Dialog>
      <span className={classnames("p-float-label", styles.inputName)}>
        <InputText
          id="list-name"
          value={listName}
          onChange={(e: any) => setListName(e.target.value)}
        />
        <label htmlFor="list-name">Create list (name)</label>
      </span>
      <Button
        className="p-button-success"
        icon="pi pi-check"
        iconPos="right"
        label="Add"
        onClick={onClick}
        disabled={!listName.length}
      />
    </div>
  );
};
