import React from "react";
import { ITimeItem } from "../../interfaces/time-item";
import { OrderList } from "primereact/orderlist";
import { Button } from "primereact/button";

import { getDuration } from "../../utils/time";

import styles from "./list.module.css";
import "./list.css";

interface ListProps {
  items?: ITimeItem[];
  onChange: (e: any) => void;
  onRemoveTimeItem: (id: string) => void;
}

export default ({ items, onChange, onRemoveTimeItem }: ListProps) => {
  const timeItemTemplate = (time: ITimeItem) => {
    const onClickRemoveItem = () => onRemoveTimeItem(time.id);
    return (
      <div className={styles.listItem}>
        <Button
          icon="pi pi-times-circle"
          onClick={onClickRemoveItem}
          className={"p-button-danger"}
        />
        <span className={styles.textItem}>
          {time.title} - {getDuration(time.duration)}
        </span>
      </div>
    );
  };

  return (
    <OrderList
      id="time-list"
      value={items}
      itemTemplate={timeItemTemplate}
      dragdrop={true}
      onChange={onChange}
      className={styles.list}
    ></OrderList>
  );
};
